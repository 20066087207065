import { SharedStateProvider, useSharedState } from "./SharedStateContext";

import "./Guest.css";
import "./Middle.css";
import Header from "./Header";
import Middle from "./Middle";
import ControlBar from "./ControlBar";
import PINPopup from "./PINPopup";
import TOS from "./TOS";
import PrivacyPolicy from "./PrivacyPolicy";

import { useParams } from "react-router-dom";

function Guest() {
  const { guid, action } = useParams();
  const {
    propertyId,
    setPropertyId,
    isPINPopupOpen,
    setIsPINPopupOpen,
    initialAction,
    setInitialAction,
  } = useSharedState();

  setPropertyId(guid);
  setInitialAction(action);

  return (
    <div className="guest">
      <Header />
      <Middle />
      <ControlBar />
      <PINPopup />
      <TOS />
      <PrivacyPolicy />
    </div>
  );
}

export default Guest;
