import React from "react";
import "./AskBalloon.css";

function AskBalloon({ text }) {
  return text !== null ? (
    <div className="askBalloon">
      <div className="askBalloonContent">{text}</div>
    </div>
  ) : null;
}

export default AskBalloon;
