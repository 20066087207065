import "./Middle.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from "./Main";
import Guest from "./Guest";
import Owner from "./Owner";
import { SharedStateProvider, useSharedState } from "./SharedStateContext";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Main />} />
        <Route
          exact
          path="/guest/:guid/:action?"
          element={
            <SharedStateProvider>
              <Guest />
            </SharedStateProvider>
          }
        />
        <Route exact path="/owner" element={<Owner />} />
      </Routes>
    </Router>
  );
}

export default App;
