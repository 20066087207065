import React, { createContext, useContext, useState } from "react";

// Create the context
const SharedStateContext = createContext();

// Create a provider component
export function SharedStateProvider({ children }) {
  const [answers, setAnswers] = useState([]);
  const [propertyId, setPropertyId] = useState();
  const [isPINPopupOpen, setIsPINPopupOpen] = useState();
  const [firstName, setFirstName] = useState();
  const [jwtToken, setJwtToken] = useState();
  const [propertyName, setPropertyName] = useState();
  const [isTOSOpen, setIsTOSOpen] = useState();
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState();
  const [initialAction, setInitialAction] = useState();

  return (
    <SharedStateContext.Provider
      value={{
        answers,
        setAnswers,
        propertyId,
        setPropertyId,
        isPINPopupOpen,
        setIsPINPopupOpen,
        firstName,
        setFirstName,
        jwtToken,
        setJwtToken,
        propertyName,
        setPropertyName,
        isTOSOpen,
        setIsTOSOpen,
        isPrivacyPolicyOpen,
        setIsPrivacyPolicyOpen,
        initialAction,
        setInitialAction,
      }}
    >
      {children}
    </SharedStateContext.Provider>
  );
}

// Custom hook for accessing the shared state
export function useSharedState() {
  return useContext(SharedStateContext);
}
