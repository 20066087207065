import React from "react";

import "./BalloonArea.css";
import AskBalloon from "./AskBalloon";
import AnswerBalloon from "./AnswerBalloon";
import AnswerImage from "./AnswerImage";
import { useSharedState } from "./SharedStateContext";

function BalloonArea({ text }) {
  const { answers } = useSharedState();

  return (
    <div className="balloonArea">
      {answers.map((answer, index) => (
        <>
          <AskBalloon text={answer.q} />
          <AnswerBalloon text={answer.a} />
          {answer.imageIds && answer.imageIds.length > 0 && (
            answer.imageIds.map((imageId, imageIndex) => (
            <AnswerImage imageId={imageId} />
          )))}
        </>
      ))}
    </div>
  );
}

export default BalloonArea;
