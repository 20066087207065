// React imports
import React, { useState, useEffect, useRef } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

// Shared state
import { useSharedState } from "./SharedStateContext";

// Packages
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxHeight: "60%", // Adjust the maximum height as needed
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

// PIN Popup component
function TOS() {
  const { isTOSOpen, setIsTOSOpen } = useSharedState();

  function toggleTOS() {
    setIsTOSOpen(false);
  }

  return (
    <div>
      <Modal
        open={isTOSOpen}
        onClose={toggleTOS}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
            onClick={toggleTOS}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Terms of Service
          </Typography>
          <br />
          <Typography id="modal-modal-title" variant="body2" component="h2">
            By using StayPro.AI, you agree to comply with and be bound by the
            following terms and conditions of use. If you disagree with any part
            of these terms, please do not use our services.
          </Typography>
          <br />
          <Typography id="modal-modal-title" variant="body2" component="h2">
            <b>1. Acceptance of Terms</b>
            <br />
            By accessing or using the services provided by StayPro.AI, you agree
            to be bound by these Terms of Service, our Privacy Policy, and any
            additional terms and conditions that may apply to specific services
            or features.
          </Typography>
          <br />
          <Typography id="modal-modal-title" variant="body2" component="h2">
            <b>2. User Registration and Access</b>
            <br />
            a. Some functionalities and information on StayPro.AI are accessible
            only to registered guests of this vacation rental home.
            <br />
            b. To access certain features on StayPro.AI, including detailed
            recommendations and problem reporting, you may be required to log
            in.
          </Typography>
          <br />
          <Typography id="modal-modal-title" variant="body2" component="h2">
            <b>3. Use of OpenAI Services</b>
            <br />
            a. StayPro.AI integrates services provided by OpenAI, including
            language models and chat APIs.
            <br />
            b. Your use of OpenAI services is subject to OpenAI's terms of
            service, which can be found on the OpenAI website.
          </Typography>
          <br />
          <Typography id="modal-modal-title" variant="body2" component="h2">
            <b>4. User Responsibilities</b>
            <br />
            a. Registered guests are responsible for maintaining the
            confidentiality of their account credentials.
            <br />
            b. Users agree not to use StayPro.AI for any unlawful purpose or to
            violate the rights of others.
          </Typography>
          <br />
          <Typography id="modal-modal-title" variant="body2" component="h2">
            <b>5. Chat Functionality and Response Quality</b>
            <br />
            a. Users are responsible for the content they generate in the chat.
            <br />
            b. StayPro.AI offers guidance, recommendations and problem solutions
            related to the vacation rental home.
            <br />
            c. Recommendations are generated using OpenAI's models and
            information provided by the owner/property manager of the home, and
            are not guaranteed to be error-free or exhaustive.
            <br />
            d. In the event that a chat response generated by StayPro.AI does
            not make sense, is inaccurate, or raises concerns, users are
            encouraged to contact the respective property owner or manager
            directly for clarification or assistance.
            <br />
            e. StayPro.AI and its affiliates are not liable for any
            misunderstandings, inaccuracies, or issues arising from chat
            interactions, and users should seek resolution through direct
            communication with the property owner or manager.
          </Typography>
          <br />
          <Typography id="modal-modal-title" variant="body2" component="h2">
            <b>6. Intellectual Property</b>
            <br />
            All content on StayPro.AI, including text, graphics, logos, images,
            and software, is the property of StayPro.AI and is protected by
            copyright and other intellectual property laws.
          </Typography>
          <br />
          <Typography id="modal-modal-title" variant="body2" component="h2">
            <b>7. Termination</b>
            <br />
            StayPro.AI reserves the right to terminate or suspend user's access
            to services at any time, with or without cause, and with or without
            notice.
          </Typography>
          <br />
          <Typography id="modal-modal-title" variant="body2" component="h2">
            <b>8. Disclaimers and Limitation of Liability</b>
            <br />
            a. StayPro.AI makes no warranties, expressed or implied, regarding
            the accuracy, reliability, or completeness of any information or
            content provided.
            <br />
            b. In no event shall StayPro.AI be liable for any direct, indirect,
            incidental, special, consequential, or punitive damages.
          </Typography>
          <br />
          <Typography id="modal-modal-title" variant="body2" component="h2">
            <b>9. Governing Law</b>
            <br />
            These Terms of Service shall be governed by and construed in
            accordance with the laws of Washington State.
          </Typography>
          <br />
          <Typography id="modal-modal-title" variant="body2" component="h2">
            <b>10. Changes to Terms</b>
            <br />
            StayPro.AI reserves the right to update, change, or replace any part
            of these Terms of Service by posting updates and/or changes on our
            website.
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}

export default TOS;
