import "./AnswerImage.css";

// React imports
import React, { useEffect, useState } from "react";

// Shared state
import { useSharedState } from "./SharedStateContext";

import axios from 'axios';

function AnswerImage({ imageId }) {

  const {
    propertyId,
    jwtToken
  } = useSharedState();

  const [imageData, setImageData] = useState(null);

  useEffect(() => {
    async function fetchImageData() {
      let headers = {};
      
      if (jwtToken) {
        headers = {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json',
        };
      }

      const response = await axios.get(
        `https://hama-chat.azurewebsites.net/api/getimage?pid=${propertyId}&imageId=${imageId}`,
        {headers, responseType: 'arraybuffer'}
      );

      const imageBlob = new Blob([response.data], { type: 'image/jpeg' });
      const imageUrl = URL.createObjectURL(imageBlob);

      setImageData(imageUrl);
    }

    fetchImageData();
  }, []);

  return (
    <div className="answerImage">
      {imageData ? (
        <img src={imageData} />
      ) : (
        <p>Loading image...</p>
      )}
    </div>
  );
}

export default AnswerImage;
