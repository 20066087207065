import React from "react";
import "./AnswerBalloon.css";

function AnswerBalloon({ text }) {
  return (
    <div className="answerBalloon">
      <div className="answerBalloonContent">{text}</div>
    </div>
  );
}

export default AnswerBalloon;
