import Welcome from "./Welcome";
import BalloonArea from "./BalloonArea";
import React, { useEffect, useRef } from "react";
import { useSharedState } from "./SharedStateContext";

function Middle() {

  const { answers } = useSharedState();

  const scrollableContentRef = useRef();

  useEffect(() => {
    const scrollableContent = scrollableContentRef.current;
    if (scrollableContent) {
      requestAnimationFrame(() => {
      scrollableContent.scrollTop = scrollableContent.scrollHeight;});
    }
  }, [answers]);

  return (
    <div id="scrollable-content" ref={scrollableContentRef} className="middle">
        <Welcome/>
        <BalloonArea/>
    </div>
  );
}

export default Middle;
