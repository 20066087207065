// React imports
import React, { useState, useEffect, useRef } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

// Shared state
import { useSharedState } from "./SharedStateContext";

// Packages
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxHeight: "60%", // Adjust the maximum height as needed
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

// PIN Popup component
function PrivacyPolicy() {
  const { isPrivacyPolicyOpen, setIsPrivacyPolicyOpen } = useSharedState();

  function togglePrivacyPolicy() {
    setIsPrivacyPolicyOpen(false);
  }

  return (
    <div>
      <Modal
        open={isPrivacyPolicyOpen}
        onClose={togglePrivacyPolicy}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
            onClick={togglePrivacyPolicy}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Privacy Policy
          </Typography>
          <br />
          <Typography id="modal-modal-title" variant="body2" component="h2">
            This Privacy Policy explains how we collect, use and protect your
            personal information.
          </Typography>
          <br />
          <Typography id="modal-modal-title" variant="body2" component="h2">
            <b>1. Information We Collect</b>
            <br />
            a. We collect information about you and your stay, including your
            name, contact information, and reservation details, from the
            existing reservation provided by the Online Travel Agency you booked
            with, the reservation management system used by the property
            manager, or directly from the property manager themselves.
            <br />
            b. Your interactions with the chat, including questions, requests,
            responses, and session information, may be stored for the purpose of
            improving the service and complying with terms of use required by
            the services we leverage to provide the chat experience.
          </Typography>
          <br />
          <Typography id="modal-modal-title" variant="body2" component="h2">
            <b>2. How We Use Your Information</b>
            <br />
            a. We use your information to provide chat services tailored to your
            stay.
            <br />
            b. Your interactions with the chat may be analyzed to enhance and
            optimize our services.
          </Typography>
          <br />
          <Typography id="modal-modal-title" variant="body2" component="h2">
            <b>3. Data Security</b>
            <br />
            We implement reasonable security measures to protect your
            information from unauthorized access, disclosure, or alteration
          </Typography>
          <br />
          <Typography id="modal-modal-title" variant="body2" component="h2">
            <b>4. Sharing Your Information</b>
            <br />
            Your information, including interactions with the chat, may be
            shared with the provider of the chat services in case of suspected
            abuse of the system or violation of our terms of use.
          </Typography>
          <br />
          <Typography id="modal-modal-title" variant="body2" component="h2">
            <b>5. Data Retention</b>
            <br />
            We retain your information for as long as necessary to fulfill the
            purposes outlined in this policy unless a longer retention period is
            required by law.
          </Typography>
          <br />
          <Typography id="modal-modal-title" variant="body2" component="h2">
            <b>6. Your Choices</b>
            <br />
            You may access and control your information by reaching out to us
            through the contact information provided below.
          </Typography>
          <br />
          <Typography id="modal-modal-title" variant="body2" component="h2">
            <b>7. Updates to this Policy</b>
            <br />
            We will notify you of any updates to this Privacy Policy by posting
            the revised policy on our website.
          </Typography>
          <br />
          <Typography id="modal-modal-title" variant="body2" component="h2">
            <b>8. Contact Us</b>
            <br />
            If you have any questions or concerns about this Privacy Policy,
            please contact us at support@staypro.ai.
          </Typography>
          <br />
        </Box>
      </Modal>
    </div>
  );
}

export default PrivacyPolicy;
