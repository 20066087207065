import "./Header.css";

import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import SendIcon from "@mui/icons-material/Send";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

import React, { useEffect, useRef, useState } from "react";

// Shared state
import { useSharedState } from "./SharedStateContext";

function Header() {
  const {
    answers,
    setAnswers,
    propertyName,
    setIsPINPopupOpen,
    jwtToken,
    setJwtToken,
    firstName,
    setFirstName,
  } = useSharedState();

  // Triggers login popup (the popup is a separate component)
  function handleLoginButton() {
    setIsPINPopupOpen(true);
  }

  // Handle logout by resetting jwtToken
  function handleLogoutButton() {
    document.cookie = "jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    const answer = { q: null, a: `Thanks, ${firstName}, we logged you out.` };

    setJwtToken("");
    setFirstName(null);

    setAnswers([...answers, answer]);
  }

  return (
    <Grid
      container
      spacing={2}
      padding={1}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={10}>
        Powered by StayPro.AI
      </Grid>
      <Grid item xs={2}>
        <Tooltip title={jwtToken ? "Log out" : "Log in"}>
          <Button
            variant="contained"
            endIcon={jwtToken ? <LogoutIcon /> : <LoginIcon />}
            onClick={jwtToken ? handleLogoutButton : handleLoginButton}
            style={{ height: "100%" }}
          ></Button>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

export default Header;
